/* CSS | SCSS */

require('../css/global.scss');
require('../css/app.css');
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');
require('webpack-jquery-ui/css');
require('select2/dist/css/select2.min.css');
require('@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css');


/* JS | JQUERY */

var $ = require('jquery');
global.$ = $;
global.jQuery = $;
require('bootstrap');

require('webpack-jquery-ui');

require('select2/dist/js/select2.min.js');

$(document).ready(function () {
    $('.datepicker')//.datepicker();
        .each(function () {
            var options = $.parseJSON($(this).data('options').replace(/'/g, '"'));
            $(this).datepicker(options);
        });

    $('select').select2({
        theme: 'bootstrap4',
    });
});



/* Images */

const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|webp)$/);
imagesContext.keys().forEach(imagesContext);